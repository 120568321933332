<script setup>
const props = defineProps({
  item: Object,
})

const name = ref('')
onMounted(() => {
  useListen('newsletter', data => {
    name.value = data.fullName
  })
})
</script>

<template>
  <div class="module-custom-message ts-label-m" v-if="name">
    {{
      useLabel('newsletter.customMessage', {
        fullName: name.toString(),
      })
    }}
  </div>
</template>

<style lang="postcss">
.module-custom-message {
  position: relative;
}
</style>
