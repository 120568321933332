export async function useNewsletterSubscribe(email, firstname, plusone) {
    const infos = { email: email, firstName: firstname, plusOne: plusone };
    // console.log(infos)
    const response = await fetch('/api/newsletter', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(infos)
    });

    const responseData = await response.json();

    if (response.ok) {
        return responseData;
    } else {
        throw new Error(`Newsletter subscription failed: ${responseData.message}`);
    }
}