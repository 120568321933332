<script setup>
const emit = defineEmits(['swiper-slide-next'])

const slideNext = () => {
  emit('swiper-slide-next')
}
</script>

<template>
  <button
    @click="slideNext"
    class="carousel-controls-hidden"
    aria-label="next"
  />
</template>

<style lang="postcss">
.carousel.controls-hidden {
}
</style>
