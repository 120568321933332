<script setup>
const { data } = await useCmsFetch('entries', {
  query: { drafts: true },
})

const element = ref(null)
const theme = {
  text: {
    hsl: ['0', '0%', '100%'],
    opacity: 100,
  },
  background: {
    hsl: ['0', '0%', '0%'],
    opacity: 100,
  },
}

useTheme({ element, theme })

const populatedData = computed(() => {
  return data.value?.data.filter(entry => entry.meta.title) || []
})
</script>

<template>
  <div class="coming-soon" ref="element">
    <div class="top">
      <h2 class="title ts-h2">{{ useLabel('general.comingSoon') }}</h2>
      <p class="number ts-number">{{ zeroPad(populatedData?.length) }}</p>
    </div>

    <ul>
      <li v-for="entry in populatedData" :key="entry.meta.id">
        <p class="ts-body-xl">{{ entry.meta.title }}</p>
        <span class="trumpet ts-label-s" v-if="entry.trumpet">
          {{ entry.trumpet }}
        </span>

        <!-- <common-media
          class="media"
          :data="entry.backgroundForeground?.background"
        /> -->
      </li>
    </ul>
  </div>
</template>

<style lang="postcss">
.coming-soon {
  .top {
    padding-bottom: units(6);

    @media (--md) {
      display: flex;
      align-items: flex-end;
      gap: units(3);
      padding-bottom: units(10);
    }
  }

  .title {
    display: inline;

    @media (--md) {
      display: block;
    }
  }

  .number {
    display: inline;
    margin-left: 1em;

    white-space: nowrap;
    margin-bottom: 0.5em;

    @media (--md) {
      display: block;
      margin-left: 0;
      margin-bottom: 0.8em;
    }
  }

  ul {
    display: grid;
    gap: units(3);

    @media (--md) {
      gap: units(2);
    }
  }

  li {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: units(1);

    @media (--md) {
      flex-direction: row;
      align-items: flex-end;
      gap: units(2);
    }

    /* &:hover {
      .media {
        opacity: 1;
      }
    } */
  }

  .trumpet {
    margin-bottom: 0.5em;
  }

  /* .media {
    position: absolute;
    width: calc(var(--sidebar-width) - var(--sidebar-margin) * 2);
    height: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: var(--br);

    opacity: 0;
  } */
}
</style>
